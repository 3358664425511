"use strict";

// Removed unused vimeo player.js import to avoid vimeo cookies (Version 3.0.6).

//todo remove when ts
// @ts-ignore
import { getPrefixedDataSet } from '@elements/data-set-utils';
// @ts-ignore
import { onFind } from "@elements/init-modules-in-scope";
// @ts-ignore
import { findIn, on } from "@elements/dom-utils";
// @ts-ignore
import loadScript from '@elements/load-script';
const defaultOptions = {
    licenseKey: '7AF43751-F35F4C70-A604AE1C-12C521AE',
    download: false,
    zoom: false,
    video: false,
    plugins: []
};
const defaultSelectors = {
    base: '.js-lightbox',
    item: '.js-lightbox__item'
};
export function init(options = defaultOptions, selectors = defaultSelectors) {
    onFind(selectors.base, function (baseElement) {
        createLightbox(baseElement, { ...defaultOptions, ...options }, { ...defaultSelectors, ...selectors });
    });
}
export function createLightbox(lightbox, options = defaultOptions, selectors = defaultSelectors) {
    let lightboxItems = findIn(selectors.item, lightbox);
    let elementOptions = {
        ...defaultOptions,
        ...options,
        ...getPrefixedDataSet('lightbox', lightbox)
    };
    let imports = [
        import('lightgallery')
    ];
    if (options.plugins) {
        options.plugins.map((item) => {
            imports.push(import('lightgallery/plugins/' + item + '/lg-' + item + '.umd.js'));
        });
    }
    let modules = [];
    imports.map((promise, index) => {
        if (index !== 0) {
            modules.push('module-' + index);
        }
    });
    Promise.all(imports).then(([lightGallery, ...modules]) => {
        elementOptions.plugins = [];
        lightGallery = lightGallery.default;
        modules.map((module) => {
            elementOptions.plugins.push(module.default);
        });
        if (elementOptions.dynamic && elementOptions.dynamicEl) {
            elementOptions.dynamicEl = JSON.parse(elementOptions.dynamicEl);
        }
        const lightboxInstance = lightGallery(lightbox, {
            ...elementOptions,
            selector: selectors.item,
        });
        //prevent isClickable
        const lightboxClickHandler = (evt) => {
            evt.stopImmediatePropagation();
            if (elementOptions.dynamic) {
                lightboxInstance.openGallery(0);
            }
        };
        if (lightboxItems) {
            on('click', lightboxClickHandler, lightboxItems);
        }
    });
}
