import {onFind} from "@elements/init-modules-in-scope";
import {
    findIn,
    findAll,
    findAllIn,
    addClass,
    on,
    closest,
    removeClass,
    removeAttribute,
    setAttribute,
    trigger, find, hasClass
} from '@elements/dom-utils';
import {getPrefixedDataSet} from "@elements/data-set-utils";
import {translate} from '@elements/translations';

const defaultOptions = {
    arrows: true,
    counter: true,
    dots: false,
    slides: 1,
    dotsClass: 'scroll-slider__dot',
    arrowClass: 'scroll-slider__arrow',
    arrowPrevClass: 'scroll-slider__arrow--prev',
    arrowNextClass: 'scroll-slider__arrow--next',
    arrowIconClass: 'icon icon-arrow',
    dotsRenderFunction: defaultDotsRenderFunction,
    arrowRenderFunction: defaultArrowRenderFunction
};

const defaultSelectors = {
    base: '.js-scroll-slider',
    area: '.js-scroll-slider__area',
    item: '.js-scroll-slider__item',
    dotsContainer: '.js-scroll-slider__dots',
    arrowsContainer: '.js-scroll-slider__arrows'
};

const INITIALIZED_EVENT = 'scroll-slider/initialized';

export function init(options = defaultOptions, selectors = defaultSelectors) {
    onFind(selectors.base, function (baseElement) {
        let modalParent = closest('.modal', baseElement);
        let tabPaneParent = closest('.tab-pane:not(.active)', baseElement);
        let collapseParent = closest('.collapse', baseElement);

        if (modalParent) {
            modalParent.addEventListener('shown.bs.modal', function (event) {
                createScrollSlider(
                    baseElement,
                    {...defaultOptions, ...options},
                    {...defaultSelectors, ...selectors}
                );
            })
        }
        else if (tabPaneParent) {
            const targetId = "#" + tabPaneParent.getAttribute('id');
            const triggerElements = findAll(`[data-bs-toggle=tab][data-bs-target="${targetId}"]`);

            triggerElements.map(triggerElement => {
                on('shown.bs.tab', function () {
                    createScrollSlider(
                        baseElement,
                        {...defaultOptions, ...options},
                        {...defaultSelectors, ...selectors}
                    );
                }, triggerElement);
            })
        }
        else if (collapseParent) {
            collapseParent.addEventListener('shown.bs.collapse', function (event) {
                createScrollSlider(
                    baseElement,
                    {...defaultOptions, ...options},
                    {...defaultSelectors, ...selectors}
                );
            })
        } else {
            createScrollSlider(
                baseElement,
                {...defaultOptions, ...options},
                {...defaultSelectors, ...selectors}
            );
        }
    });
}

export function createScrollSlider(baseElement, options = defaultOptions, selectors = defaultSelectors) {
    options = {
        ...defaultOptions,
        ...options,
        ...getPrefixedDataSet('scroll-slider', baseElement)
    };

    let items = findAllIn(selectors.item, baseElement);
    let dots;

    if (items[0]) {
        let scrollArea = findIn(selectors.area, baseElement),
            scrollItems = findAllIn('.js-scroll-slider__item', baseElement),
            scrollCounterCurrent = findIn('.js-scroll-slider__counter-current', baseElement),
            scrollCounterTotal = findIn('.js-scroll-slider__counter-total', baseElement),
            scrollActiveIndex = 1,
            prevButton,
            nextButton,
            dotsContainer,
            arrowsContainer,
            counter = 1;

        if (options.counter === true){
            if (scrollCounterCurrent && scrollCounterTotal){
                scrollCounterTotal.innerHTML = scrollItems.length.toString();
                scrollCounterCurrent.innerHTML = counter.toString();
            }
            setCounter(baseElement);
        }


        if (options.arrows === true) {
            arrowsContainer = findIn(selectors.arrowsContainer, baseElement);
            let arrowsRenderContainer = baseElement;

            // if there is a arrows container in the markup, take that (recommended)
            if (arrowsContainer){
                arrowsRenderContainer = arrowsContainer;
            }else{
                console.warn('NO ARROWS CONTAINER IN MARKUP (recommended). Element:', baseElement);
            }

            prevButton = options.arrowRenderFunction(arrowsRenderContainer, 0, true, options);
            nextButton = options.arrowRenderFunction(arrowsRenderContainer, 1, false, options);
        }

        if (options.dots) {
            dotsContainer = findIn(selectors.dotsContainer, baseElement);
        }

        const scrollWidth = getScrollWidth(items[0]);

        //set initial scroll position to 0
        scrollArea.scrollLeft = 0;
        findAllIn(selectors.item, baseElement).map((item, index) => {
            if (index === 0) {
                addClass('is-active', item);
                scrollArea.setAttribute('data-scroll-slider-active-index', scrollActiveIndex);
            }

            getActiveItems(item, index);

            if (dotsContainer) {
                let newDot = options.dotsRenderFunction(index, dotsContainer, options);

                on('click', function () {
                    scrollTo(scrollArea, Math.floor(item.getBoundingClientRect().left) + scrollArea.scrollLeft - Math.floor(scrollArea.getBoundingClientRect().left))
                }, newDot);
            }
        });

        if (dotsContainer) {
            dots = findAllIn('.js-scroll-slider__dots-btn', dotsContainer);
        }

        if (nextButton || prevButton) {
            if (options.slides >= findAllIn(selectors.item, baseElement).length) {
                addClass('d-none', prevButton);
                addClass('d-none', nextButton);
            }

            on('click', function () {
                scrollTo(scrollArea, scrollArea.scrollLeft + scrollWidth, );
            }, nextButton);


            on('click', function () {
                scrollTo(scrollArea, scrollArea.scrollLeft - scrollWidth, );

            }, prevButton);
        }

        let scrollXPos = 0;

        scrollArea.addEventListener('scroll', function (e) {
            if (dots) {
                dots.map((item) => removeClass('is-active', item));
            }

            if (options.counter === true){
                let atSnappingPoint = e.target.scrollLeft % e.target.offsetWidth === 0;
                let timeOut         = atSnappingPoint ? 0 : 150; //see notes

                clearTimeout(e.target.scrollTimeout); //clear previous timeout

                e.target.scrollTimeout = setTimeout(function() {
                    //using the timeOut to evaluate scrolling state
                    if (!timeOut) {
                        if (scrollArea.scrollLeft > scrollXPos){
                            items.forEach((item, index) => {
                                if(hasClass('is-active', item)){
                                    scrollActiveIndex = index + 1;
                                }
                            });
                            scrollArea.setAttribute('data-scroll-slider-active-index', scrollActiveIndex);
                        }else{
                            items.forEach((item, index) => {
                                if(hasClass('is-active', item)){
                                    scrollActiveIndex = index + 1;
                                }
                            });
                            scrollArea.setAttribute('data-scroll-slider-active-index', scrollActiveIndex);
                        }
                        scrollXPos = scrollArea.scrollLeft;

                        setCounter(baseElement);

                    }
                }, timeOut);
            }

            if (nextButton) {
                removeAttribute('disabled', nextButton);
            }

            if (prevButton) {
                if (scrollArea.scrollLeft === 0) {
                    setAttribute('disabled', true, prevButton);
                } else {
                    removeAttribute('disabled', prevButton);
                }
            }

            items.map((item, index) => {
                removeClass('is-active', item);

                getActiveItems(item, index);
            });

            console.log('active-scroll index', scrollActiveIndex);
        }, false);

        updateScrollWidth(items[0]);

        trigger(INITIALIZED_EVENT, baseElement);

        function getActiveItems(item, index) {
            let itemLeft = Math.floor(item.getBoundingClientRect().left),
                areaOffsetLeft = Math.floor(scrollArea.getBoundingClientRect().left),
                areaWidth = Math.floor(scrollArea.getBoundingClientRect().width),
                areaPaddingLeft = Math.floor(parseInt(getComputedStyle(scrollArea).paddingLeft));

            if (itemLeft >= areaPaddingLeft && itemLeft >= areaOffsetLeft &&
                itemLeft + scrollWidth - 1 <= areaWidth + areaPaddingLeft + areaOffsetLeft &&
                itemLeft < areaWidth - areaPaddingLeft + areaOffsetLeft) {
                addClass('is-active', item);

                if (dots) {
                    addClass('is-active', dots[index]);
                }

                //disable button if last item is active
                if (nextButton && items.slice(-1)[0] === item) {
                    setAttribute('disabled', true, nextButton)
                }
            }
        }
    }
}

export function scrollTo(scrollArea, scroll) {
    try {
        scrollArea.scroll({
            left: scroll,
            behavior: 'smooth'
        });

    } catch (e) {
        scrollArea.scrollLeft = scroll;
    }
}

export function getScrollWidth(item) {
    return Math.floor(item.getBoundingClientRect().width);
}

function updateScrollWidth(item) {
    let delay = 250,
        throttled = false;

    window.addEventListener('resize', function() {
        if (!throttled) {
            getScrollWidth(item);
            throttled = true;
            setTimeout(function() {
                throttled = false;
            }, delay);
        }
    });
}

function defaultDotsRenderFunction(id = null, dotContainer, options) {
    let markupElement = document.createElement('div'),
        dotMarkup = `<button type="button" class="${options.dotsClass} js-scroll-slider__dots-btn ${id === 0 ? 'is-active' : ''}" data-id="${id+1}"></button>`;
    markupElement.innerHTML = dotMarkup;
    let newDot = markupElement.childNodes[0];
    dotContainer.appendChild(newDot);

    return newDot;
}

function defaultArrowRenderFunction(container, direction, disabled, options) {
    let markupElement = document.createElement('div'),
        arrowMarkup = `<button type="button" class="${options.arrowClass} ${direction === 0 ? options.arrowPrevClass : options.arrowNextClass}" ${disabled ? 'disabled' : ''} aria-label="${translate('scroll-slider-'+ direction + '')}"><span class="scroll-slider__arrow-icon ${options.arrowIconClass}"></span></button>`;
    markupElement.innerHTML = arrowMarkup;
    let newArrow = markupElement.childNodes[0];

    container.appendChild(newArrow);

    return newArrow;
}


function setCounter(baseElement) {
    let scrollItems = findAllIn('.js-scroll-slider__item', baseElement);
    let currentCount = findIn('.js-scroll-slider__counter-current', baseElement);

    let counter = findIn('.js-scroll-slider__area', baseElement).getAttribute('data-scroll-slider-active-index');

    if (currentCount && counter){
        currentCount.innerHTML = counter.toString();

        let strokeDashOffsetValue = 100 - (counter / scrollItems.length * 100);
        let progressBar = find(".js-scroll-slider__counter-progress-bar");
        progressBar.style.strokeDashoffset = strokeDashOffsetValue;
    }
}